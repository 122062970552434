<template>
  <AppPanel @close="handleClose">
    <div class="event-panel__header">
      <button class="event-panel__header-close" @click="handleClose">
        <SvgIcon icon="x" />
      </button>
    </div>
    <div v-if="eventsStore.currentEvent" class="event-panel__body">
      <div class="event-panel__wrapper">
        <span class="event-panel__title">Тема события</span>
        <div class="event-panel__theme">
          <span class="event-panel__theme-value">{{
            eventsStore.currentEvent.theme
          }}</span>
          <SvgIcon
            @click="openEditPopup"
            icon="edit"
            class="event-panel__edit"
          />
        </div>
      </div>
      <div class="event-panel__wrapper">
        <span class="event-panel__title">Место события</span>
        <span class="event-panel__value">{{
          eventsStore.currentEvent.place || '-'
        }}</span>
      </div>
      <div class="event-panel__wrapper">
        <span class="event-panel__title">Дата/время</span>
        <div class="event-panel__calendar">
          <span class="event-panel__value">{{ getDate }}</span>
          <button
            @click="toggleAddCalendar"
            class="event-panel__calendar-button"
          >
            <span>Добавить в календарь</span>
            <SvgIcon icon="plus" />
          </button>
          <div v-show="tooltipOpen" class="event-panel__calendar-tooltip">
            <add-to-calendar-button
              class="event-panel__add"
              :name="eventsStore.currentEvent.theme"
              :description="eventsStore.currentEvent.comment"
              :startDate="getLinkCalendarDate.startDate"
              :startTime="getLinkCalendarDate.startTime"
              :endDate="getLinkCalendarDate.endDate"
              :endTime="getLinkCalendarDate.endTime"
              timeZone="Europe/Moscow"
              :location="eventsStore.currentEvent.place"
              availability="free"
              options="'Apple','Google','iCal','Outlook.com','Yahoo'"
              trigger="click"
              label="Добавить в календарь"
              listStyle="dropdown-static"
              hideIconList
              hideIconModal
              hideTextLabelButton
              hideTextLabelList
              buttonsList
              hideBackground
              hideCheckmark
              size="0"
            ></add-to-calendar-button>
          </div>
        </div>
      </div>
      <div class="event-panel__wrapper">
        <span class="event-panel__title">Клиент</span>
        <span class="event-panel__value">{{
          eventsStore.currentEvent.customer?.name || '-'
        }}</span>
      </div>
      <div class="event-panel__wrapper">
        <span class="event-panel__title">Оппонент</span>
        <span class="event-panel__value">{{
          eventsStore.currentEvent.lawsuit?.opponent || '-'
        }}</span>
      </div>
      <div class="event-panel__wrapper">
        <span class="event-panel__title">Тип события</span>
        <div class="event-panel__category">
          <div
            class="event-panel__category-color"
            :style="{
              background: `${eventsStore.currentEvent.lawsuitEventCategory.color}`,
            }"
          ></div>
          <span class="event-panel__value">{{
            eventsStore.currentEvent.lawsuitEventCategory.name
          }}</span>
          <span
            v-if="eventsStore.currentEvent.status"
            class="event-panel__category-status"
            >{{ getStatus }}</span
          >
        </div>
      </div>
      <div v-if="eventsStore.currentEvent.lawsuit" class="event-panel__wrapper">
        <span class="event-panel__title">Дело</span>
        <span class="event-panel__value">{{
          eventsStore.currentEvent.lawsuit.hintText || '-'
        }}</span>
      </div>
      <div v-if="eventsStore.currentEvent.lawsuit" class="event-panel__wrapper">
        <span class="event-panel__title">№ дела</span>
        <a
          v-if="
            eventsStore.currentEvent.lawsuit.authorities?.length &&
            eventsStore.currentEvent.lawsuit.authorities[0].lawsuitNumberLink
          "
          :href="
            eventsStore.currentEvent.lawsuit.authorities[0].lawsuitNumberLink
          "
          target="_blank"
          class="event-panel__value-link"
          >{{
            eventsStore.currentEvent.lawsuit.authorities[0].lawsuitNumber
          }}</a
        >
        <span v-else class="event-panel__value-link">-</span>
      </div>
      <div class="event-panel__wrapper">
        <span class="event-panel__title">Стоимость, руб</span>
        <span class="event-panel__value">{{
          eventsStore.currentEvent.cost || '-'
        }}</span>
      </div>
      <div
        v-if="eventsStore.currentEvent?.lawsuit?.authorities?.length"
        class="event-panel__wrapper"
      >
        <span class="event-panel__title">Орган рассмотрения</span>
        <div class="event-panel__calendar">
          <span class="event-panel__value">{{
            eventsStore.currentEvent?.lawsuit?.authorities[0].judge
          }}</span>
          <button
            @click="reviewBodiesHandler"
            class="event-panel__calendar-button"
          >
            <span>Все органы рассмотрения</span>
            <SvgIcon icon="chevron-right" />
          </button>
        </div>
      </div>
      <div
        v-if="
          eventsStore.currentEvent?.lawsuit?.contractNumber ||
          eventsStore.currentEvent?.lawsuit?.powerOfAttorney
        "
        class="event-panel__agreement"
      >
        <span class="event-panel__agreement-title">Договор</span>
        <div class="event-panel__wrapper">
          <span class="event-panel__title">Номер</span>
          <span class="event-panel__value">{{
            eventsStore.currentEvent?.lawsuit?.contractNumber || '-'
          }}</span>
        </div>
        <div class="event-panel__wrapper">
          <span class="event-panel__title">Срок действия</span>
          <span class="event-panel__value">{{
            formatDate(eventsStore.currentEvent?.lawsuit?.contractValidity) ||
            '-'
          }}</span>
        </div>
        <span class="event-panel__agreement-title">Доверенность</span>
        <div class="event-panel__wrapper">
          <span class="event-panel__title">Номер</span>
          <span class="event-panel__value">{{
            eventsStore.currentEvent?.lawsuit?.powerOfAttorney || '-'
          }}</span>
        </div>
        <div class="event-panel__wrapper">
          <span class="event-panel__title">Действует до</span>
          <span class="event-panel__value">{{
            formatDate(
              eventsStore.currentEvent?.lawsuit?.powerOfAttorneyValidity,
            ) || '-'
          }}</span>
        </div>
      </div>
      <div class="event-panel__wrapper">
        <span class="event-panel__title">Примечание</span>
        <span class="event-panel__value">{{
          eventsStore.currentEvent?.comment || '-'
        }}</span>
      </div>
      <article v-if="eventsStore.currentEvent.task" class="event-panel__event">
        <div class="event-box">
          <span class="gray-text">Связанная задача</span>
          <p @click="openTask" class="blue-text blue-text_link">
            {{ eventsStore.currentEvent.task.theme }}
          </p>
        </div>
        <button
          @click="deleteLawsuitTask"
          type="button"
          class="event-panel__event-remove-btn"
        >
          <SvgIcon icon="trash04" />
        </button>
      </article>
      <article v-else class="event-panel__event">
        <span class="event-panel__title">Связанная задача</span>
        <div class="event-panel__event-empty">
          <span class="event-panel__value">Связанная задача отсутствует</span>
          <button
            v-if="authStore.manageParts.includes('tasks')"
            @click="addConnectTask"
            type="button"
            class="event-panel__event-add-btn"
          >
            Добавить
            <SvgIcon icon="plus" />
          </button>
        </div>
      </article>
    </div>
    <div
      v-if="eventsStore.isCurrentEventLoading"
      class="event-panel__loader"
    ></div>
    <div class="event-panel__actions">
      <router-link
        v-if="eventsStore.currentEvent?.lawsuit"
        :to="{
          name: 'lawsuit-details',
          params: { id: eventsStore.currentEvent.lawsuit.id },
        }"
        class="event-panel__actions-link"
        >Перейти в досье</router-link
      >
      <div
        class="event-panel__actions-wrapper"
        v-if="authStore.manageParts.includes('calendar')"
      >
        <button @click="openEditPopup" class="event-panel__actions-edit">
          Редактировать
        </button>
        <button @click="deleteEvent" class="event-panel__actions-delete">
          Отменить событие
        </button>
      </div>
    </div>
  </AppPanel>
</template>

<script lang="ts" src="./EventPanel.ts"></script>
<style lang="scss" scoped src="./EventPanel.scss"></style>

import {
  defineComponent,
  ref,
  onBeforeMount,
  onBeforeUnmount,
  computed,
} from 'vue'
import { useAuthStore } from '@/store/auth'
import { useUXUIStore } from '@/store/uxui'
import { getFormatDate } from '@/helpers/dateFormatter'
import { useEventsStore } from '@/store/events'
import dayjs from 'dayjs'
import { useTasksStore } from '@/store/tasks'
import { modalsContent } from '@/config/confirmationModals'
import 'add-to-calendar-button'

export default defineComponent({
  name: 'EventPanel',
  setup() {
    const eventsStore = useEventsStore()
    const authStore = useAuthStore()
    const uxuiStore = useUXUIStore()
    const isLoading = ref(false)
    const tasksStore = useTasksStore()
    const handleClose = () => {
      uxuiStore.eventVisible = false
      eventsStore.currentEvent = null
    }
    const getStatus = computed(() => {
      return eventsStore.currentEvent?.status !== 'finished'
        ? 'Запланировано'
        : 'Завершено'
    })
    const getDate = computed(() => {
      return eventsStore.currentEvent?.isAllDay
        ? 'Весь день'
        : `с ${dayjs(eventsStore.currentEvent?.since).utc().format('DD.MM.YYYY HH:mm')} по ${dayjs(eventsStore.currentEvent?.till).utc().format('DD.MM.YYYY HH:mm')}`
    })

    const getLinkCalendarDate = computed(() => {
      return {
        startDate:
          dayjs(eventsStore.currentEvent?.since).utc().format('YYYY-MM-DD') ||
          '',
        startTime:
          dayjs(eventsStore.currentEvent?.since).utc().format('HH:DD') || '',
        endDate:
          dayjs(eventsStore.currentEvent?.till).utc().format('YYYY-MM-DD') ||
          '',
        endTime:
          dayjs(eventsStore.currentEvent?.till).utc().format('HH:DD') || '',
      }
    })

    const closeOnEsc = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        handleClose()
      }
    }

    const openEditPopup = () => {
      eventsStore.editEventFormOpen()
    }

    const deleteLawsuitTask = async () => {
      if (eventsStore.currentEvent) {
        uxuiStore.setModalName('ConfirmationModal')
        uxuiStore.setModalContent(
          modalsContent['deleteConnectTask'],
          eventsStore.currentEvent.id,
        )
      }
    }

    const deleteEvent = async () => {
      if (eventsStore.currentEvent) {
        uxuiStore.setTypeOfSelectedPlan('event')
        uxuiStore.setModalName('ConfirmationModal')
        uxuiStore.setModalContent(
          modalsContent['taskEvent'],
          eventsStore.currentEvent.id,
        )
      }
    }

    const openTask = async () => {
      if (eventsStore.currentEvent && eventsStore.currentEvent.task) {
        await tasksStore.getTaskByID(eventsStore.currentEvent.task.id)
        tasksStore.editTaskFormOpen()
      }
    }

    const addConnectTask = () => {
      if (eventsStore.currentEvent) {
        eventsStore.isConnectEvent = {
          data: { ...eventsStore.currentEvent },
          needOpen: false,
        }
      }
      tasksStore.createTaskFormOpen()
    }
    const tooltipOpen = ref(false)
    const toggleAddCalendar = () => {
      tooltipOpen.value = !tooltipOpen.value
    }

    const formatDate = (date: string | null) => {
      return date ? dayjs(date).format('DD.MM.YYYY') : ''
    }

    const reviewBodiesHandler = () => {
      uxuiStore.setModalName('ReviewBodies', 2)
    }

    onBeforeMount(async () => {
      window.addEventListener('keydown', closeOnEsc)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('keydown', closeOnEsc)
    })

    return {
      authStore,
      handleClose,
      eventsStore,
      getFormatDate,
      isLoading,
      getDate,
      getStatus,
      openEditPopup,
      addConnectTask,
      deleteLawsuitTask,
      openTask,
      deleteEvent,
      getLinkCalendarDate,
      tooltipOpen,
      toggleAddCalendar,
      formatDate,
      reviewBodiesHandler,
    }
  },
})

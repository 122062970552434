import {
  defineComponent,
  PropType,
  watch,
  ref,
  onBeforeMount,
  useTemplateRef,
  onUnmounted,
} from 'vue'
import RadioFilter from '@/components/RadioFilter/RadioFilter.vue'
import ListFilterSelection from '@/components/ListFilterSelection/ListFilterSelection.vue'
import SelectElement from '@/components/SelectElement/SelectElement.vue'
import { useTagsStore } from '@/store/tags'
import { Filter, FilterGroup, SearchFiltersListPanel } from '@/types/filters'
import { useLawsuitStore } from '@/store/lawsuite'

export default defineComponent({
  name: 'LawsuitFilter',
  props: {
    data: { type: Object as PropType<FilterGroup>, required: true },
    selectedFilters: Array as PropType<SearchFiltersListPanel[]>,
  },
  components: {
    SelectElement,
    RadioFilter,
    ListFilterSelection,
  },
  emits: ['update:value'],
  setup(props, { emit }) {
    const tagsStore = useTagsStore()
    const lawsuitStore = useLawsuitStore()
    const selectOptions = ref<any[] | []>([])
    const lawsuitEventCategoryIdsRef = useTemplateRef<(typeof SelectElement)[]>(
      'lawsuitEventCategoryIdsRef',
    )
    const lawsuitCategoryIdsRef = useTemplateRef<(typeof SelectElement)[]>(
      'lawsuitCategoryIdsRef',
    )
    const selectElementRef =
      useTemplateRef<(typeof SelectElement)[]>('selectElement')
    const selectLabelText = {
      taskTagIds: 'Тэги',
      lawsuitCategoryIds: 'Категория',
      lawsuitEventCategoryIds: 'События',
    }

    const getTagsOptions = async () => {
      if (!tagsStore.tagsList) {
        await tagsStore.getTagsForTasksList()
      }
    }

    const getLawsuitCategoriesOptions = async () => {
      if (!lawsuitStore.lawsuitCategories) {
        await lawsuitStore.getLawsuitCategoriesList()
      }
    }

    const currentOptions = (key: string) => {
      const findItem = selectOptions.value.find((item) => item.key === key)
      return findItem ? findItem.values : []
    }

    const getSelectOptions = async () => {
      if (props.data?.filters) {
        for (const key in props.data.filters) {
          const data = props.data.filters[key as keyof Filter]
          if (data.options === 'tags') {
            await getTagsOptions()
            if (tagsStore.tagsList) {
              selectOptions.value = [
                ...selectOptions.value,
                { key: data.options, values: tagsStore.tagsList },
              ]
            }
          }
          if (data.options === 'lawsuitCategories') {
            await getLawsuitCategoriesOptions()
            if (lawsuitStore.lawsuitCategories) {
              selectOptions.value = [
                ...selectOptions.value,
                { key: data.options, values: lawsuitStore.lawsuitCategories },
              ]
            }
          }
        }
      }
    }

    const setFilterWidthOffset = () => {
      document.documentElement.style.setProperty(
        '--widthFilterOffset',
        `${(document.querySelector('.navbar__search-wrapper')?.getBoundingClientRect().width as number) - 44}px`,
      )
    }

    watch(
      () => props.selectedFilters,
      (newValue, oldValue) => {
        if (newValue && oldValue) {
          if (
            !newValue.find(
              (item) => item.parameter === 'lawsuitEventCategoryIds',
            ) &&
            oldValue.find(
              (item) => item.parameter === 'lawsuitEventCategoryIds',
            ) &&
            lawsuitEventCategoryIdsRef.value
          ) {
            lawsuitEventCategoryIdsRef.value[0].resetSelectedValue()
          }
          if (
            !newValue.find((item) => item.parameter === 'lawsuitCategoryIds') &&
            oldValue.find((item) => item.parameter === 'lawsuitCategoryIds') &&
            lawsuitCategoryIdsRef.value
          ) {
            lawsuitCategoryIdsRef.value[0].resetSelectedValue()
          }
        }
        if (
          !newValue?.find(
            (filter) =>
              filter.parameter === props.data?.filters.select?.purpose,
          ) &&
          selectElementRef.value
        ) {
          selectElementRef.value[0].resetSelectedValue()
        }
      },
    )

    const onValueUpdate = (value: any) => {
      emit('update:value', value)
    }

    const onSelectValueChange = (objKey: string, value: string | object[]) => {
      const obj = {
        value: {},
        labelText: selectLabelText[objKey as keyof typeof selectLabelText],
        text: '',
      }
      if (Array.isArray(value)) {
        obj.value = {
          [objKey]: value.length ? value.map((item: any) => item.id) : null,
        }
        obj.text = value.map((item: any) => item.name).join('+')
      }
      onValueUpdate(obj)
    }

    watch(
      () => props.data,
      async () => {
        await getSelectOptions()
      },
    )

    onBeforeMount(async () => {
      await getSelectOptions()
      setFilterWidthOffset()
      window.addEventListener('resize', setFilterWidthOffset)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', setFilterWidthOffset)
    })

    return {
      selectOptions,
      selectElementRef,
      onValueUpdate,
      onSelectValueChange,
      currentOptions,
      lawsuitEventCategoryIdsRef,
      lawsuitCategoryIdsRef,
    }
  },
})

import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { DefaultError } from '@/types/httpError'
import { useApiCall } from '@/composables/useApiCall'
import { useAuthStore } from '@/store/auth'
import {
  userDataInfoApiCall,
  workShedulePartialChangeApiCall,
  userChangeApiCall,
  deleteUserApiCall,
  changePasswordApiCall,
  getUserSubscriptionApiCall,
  changeUserTariffApiCall,
  getTariffPaymentLinkApiCall,
  cancelSubscriptionApiCall,
} from '@/api/user'
import {
  WorkShedule,
  UserDataApiResponse,
  UserData,
  UserPasswordApiPayload,
  UserTariffChangePayload,
  Subscription,
  PaymentLinkResponse,
  UserSubscriptionSuccessResponse,
} from '@/types/user'
import { useRouter } from 'vue-router'
import { useUXUIStore } from '@/store/uxui'

export const useUserStore = defineStore('user', () => {
  const uxuiStore = useUXUIStore()
  const router = useRouter()
  const authStore = useAuthStore()
  const errorFields = ref<
    null | DefaultError['error'] | DefaultError['error']['error']
  >(null)
  const userInfo = ref<UserData | null>(null)
  const selectedItem = ref<WorkShedule | null>(null)
  const dataForChange = ref<UserData | null>(null)
  const changePasswordData = ref<UserPasswordApiPayload | null>(null)
  const userSubscription = ref<Subscription | null>(null)
  const tariffPaymentData = ref<PaymentLinkResponse['data'] | null>(null)
  const isTariffPanelOpen = ref<boolean>(false)

  const setDataForChange = (payload: UserData | null): void => {
    dataForChange.value = payload
  }

  const setChangePasswordData = (payload: UserPasswordApiPayload): void => {
    changePasswordData.value = payload
  }

  const setValueByKey = <K extends keyof WorkShedule>(
    key: K,
    value: WorkShedule[K],
  ): void => {
    if (!selectedItem.value && userInfo.value) {
      selectedItem.value = {
        startWorkingTime: userInfo.value?.startWorkingTime,
        endWorkingTime: userInfo.value?.endWorkingTime,
        workingTimeInterval: userInfo.value?.workingTimeInterval,
      }
    }
    ;(selectedItem.value as Record<string, unknown>)[key] = value
  }

  // Валидация заполненных строк в настройках (Настройка рабочего дня)
  const selectedItemValidate = computed(() => {
    if (selectedItem.value === null) {
      return false
    }
    return (
      selectedItem.value.startWorkingTime !== '' &&
      selectedItem.value.endWorkingTime !== '' &&
      selectedItem.value.workingTimeInterval !== 0
    )
  })

  const setWorkShedule = (payload: WorkShedule | null) => {
    selectedItem.value = payload
  }

  const {
    data: userInfoData,
    executeApiCall: getUserInfo,
    error: userDataError,
    isLoading: isLoadingUserData,
  } = useApiCall<UserDataApiResponse, DefaultError, UserData>(
    userDataInfoApiCall,
    true,
  )

  const {
    data: changeWorkDayData,
    executeApiCall: changeWorkDay,
    error: userChangeWorkDayError,
  } = useApiCall<UserDataApiResponse, DefaultError, WorkShedule>(
    workShedulePartialChangeApiCall,
    true,
  )

  const {
    data: userChangedData,
    executeApiCall: changeDataUser,
    error: userChangeDataError,
  } = useApiCall<UserDataApiResponse, DefaultError, UserData>(
    userChangeApiCall,
    true,
  )

  const {
    data: userDeleteData,
    executeApiCall: deleteUser,
    error: userDeleteDataError,
  } = useApiCall<UserDataApiResponse, DefaultError, UserData>(
    deleteUserApiCall,
    true,
  )

  const {
    data: userChangedPassword,
    executeApiCall: changePasswordUser,
    error: passwordChangeDataError,
  } = useApiCall<UserDataApiResponse, DefaultError, UserPasswordApiPayload>(
    changePasswordApiCall,
    true,
  )

  const {
    data: userSubscriptionData,
    executeApiCall: getUserSubscriptionAction,
    error: userSubscriptionErr,
  } = useApiCall<UserSubscriptionSuccessResponse, DefaultError>(
    getUserSubscriptionApiCall,
    true,
  )
  const {
    data: newTariffData,
    executeApiCall: changeTariffAction,
    error: newTariffError,
  } = useApiCall<UserDataApiResponse, DefaultError, UserTariffChangePayload>(
    changeUserTariffApiCall,
    true,
  )
  const {
    isLoading: isPaymentLinkReqLoading,
    data: paymentLinkData,
    executeApiCall: paymentLinkAction,
    //error: paymentLinkError,
  } = useApiCall<PaymentLinkResponse, DefaultError, UserTariffChangePayload>(
    getTariffPaymentLinkApiCall,
    true,
  )
  const {
    data: cancelSubData,
    executeApiCall: cancelSubscriptionAction,
    error: cancelSubErr,
  } = useApiCall<UserDataApiResponse, DefaultError>(
    cancelSubscriptionApiCall,
    true,
  )

  const setUserInfo = (payload: UserData) => {
    userInfo.value = payload
  }

  const checkValidPassword = () => {
    if (changePasswordData.value) {
      return Object.values(changePasswordData.value).every(
        (value) => value !== '' && value !== undefined && value !== null,
      )
    }
    return false
  }

  const getUserDataInfo = async () => {
    try {
      await getUserInfo()
      if (userInfoData.value && userInfoData.value.success) {
        setUserInfo(userInfoData.value.data)
      }
    } catch {
      if (userDataError.value?.data.error) {
        errorFields.value = userDataError.value.data.error
      }
    }
  }

  const changeWorkShedule = async () => {
    if (selectedItem.value) {
      try {
        await changeWorkDay(selectedItem.value)
        if (changeWorkDayData.value) {
          setWorkShedule(null)
        }
      } catch {
        if (userChangeWorkDayError.value?.data.error) {
          errorFields.value = userChangeWorkDayError.value.data.error
        }
      }
    }
  }

  const changeDataUserRequest = async () => {
    if (dataForChange.value) {
      try {
        await changeDataUser(dataForChange.value)
        if (userChangedData.value) {
          setUserInfo(userChangedData.value.data)
          uxuiStore.openNotification('done', 'Профиль сохранен')
        }
      } catch {
        if (userChangeDataError.value?.data.error) {
          errorFields.value = userChangeDataError.value.data.error
        }
        uxuiStore.openNotification('error', 'Произошла ошибка')
      }
    }
  }

  const deleteUserRequest = async () => {
    try {
      await deleteUser()
      if (userDeleteData.value) {
        if (userDeleteData.value.success) {
          await authStore.clearStore()
          await router.push('/login')
        }
      }
    } catch {
      if (userDeleteDataError.value?.data.error) {
        errorFields.value = userDeleteDataError.value.data.error
      }
    }
  }

  const changePasswordUserRequest = async () => {
    if (changePasswordData.value && checkValidPassword()) {
      try {
        await changePasswordUser(changePasswordData.value)
        if (userChangedPassword.value) {
          console.log(userChangedPassword.value)
        }
        uxuiStore.openNotification('done', 'Пароль сохранен')
      } catch {
        if (passwordChangeDataError.value?.data.error) {
          errorFields.value = passwordChangeDataError.value.data.error
        }
        uxuiStore.openNotification('error', 'Произошла ошибка')
      }
    }
  }

  const getUserSubscription = async () => {
    try {
      await getUserSubscriptionAction()
      if (userSubscriptionData.value) {
        userSubscription.value = userSubscriptionData.value.data
      }
    } catch (error) {
      if (userSubscriptionErr.value?.data.error?.error) {
        uxuiStore.openNotification(
          'error',
          userSubscriptionErr.value?.data.error.error,
        )
      }
    }
  }

  const changeUserTariffRequest = async (tariffId: number) => {
    try {
      await changeTariffAction({ id: tariffId })
      if (newTariffData.value) {
        console.log(newTariffData.value)
        await getUserDataInfo()
      }
    } catch (error) {
      if (newTariffError.value?.data.error.error) {
        uxuiStore.openNotification(
          'error',
          newTariffError.value?.data.error.error,
        )
      }
    }
  }

  const getTariffPaymentLinkRequest = async (tariffId: number) => {
    try {
      await paymentLinkAction({ id: tariffId })
      if (paymentLinkData.value) {
        tariffPaymentData.value = paymentLinkData.value.data
      }
    } catch (error) {
      // if (paymentLinkError.value?.data.error.error) {
      //   uxuiStore.openNotification(
      //     'error',
      //     paymentLinkError.value?.data.error.error,
      //   )
      // }

      uxuiStore.openNotification('error', 'Произошла ошибка')
    }
  }

  const cancelUserSubscriptionRequest = async () => {
    try {
      await cancelSubscriptionAction()
      if (cancelSubData.value) {
        console.log(cancelSubData.value)
        await getUserDataInfo()
      }
    } catch (error) {
      if (cancelSubErr.value?.data.error.error) {
        uxuiStore.openNotification(
          'error',
          cancelSubErr.value?.data.error.error as string,
        )
      }
    }
  }

  return {
    getUserDataInfo,
    userInfo,
    errorFields,
    userSubscription,
    changeWorkShedule,
    isPaymentLinkReqLoading,
    tariffPaymentData,
    setValueByKey,
    isLoadingUserData,
    isTariffPanelOpen,
    selectedItemValidate,
    changeDataUserRequest,
    deleteUserRequest,
    changePasswordUserRequest,
    setChangePasswordData,
    setDataForChange,
    getUserSubscription,
    changeUserTariffRequest,
    getTariffPaymentLinkRequest,
    cancelUserSubscriptionRequest,
  }
})
